import { Card, CardHeader } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { Moment } from 'moment';
import { useContext, useState } from 'react';
import constants from '../../config/constants';
import { AlertContext } from '../../context/alert.context';
import useFetch from '../../hooks/useFetch';
import { IExpert, IPrivateService } from '../../types';
import { getLocalizedErrorMessage } from '../../utils/functions';
import AppointmentScheduleDialog from '../AppointmentScheduleDialog';
import PrivateServicesTable from '../PrivateServicesTable';
import PrivateSessionsTable from '../PrivateSessionsTable';

const OneToOneSessionsTab = ({ profileData }: { profileData?: IExpert }) => {
    const privateServices = useFetch<IPrivateService[]>(`${constants.API_URL}/instructor/admin/private_services`);
    const privateSessions = useFetch<any[]>(`${constants.API_URL}/instructor/admin/private_appointments`);
    const alert = useContext(AlertContext);
    const [showAppointmentDialog, setShowAppointmentDialog] = useState(false);
    const [showAppointmentDialogData, setShowAppointmentDialogData] = useState<{
        name: string;
        service: any;
        member_id: string;
        loading?: boolean;
    }>({
        loading: false,
        member_id: '',
        name: '',
        service: undefined,
    });
    return (
        <>
            <AppointmentScheduleDialog
                onClose={() => {
                    setShowAppointmentDialog(false);
                }}
                open={showAppointmentDialog}
                loading={showAppointmentDialogData.loading}
                title={`קביעת פגישה עם ${showAppointmentDialogData.name}`}
                duration={showAppointmentDialogData.service?.duration || 20}
                service={showAppointmentDialogData.service}
                onScheduleAppointmentButtonPressed={async (selectedTime: Moment, isFollowup: boolean) => {
                    setShowAppointmentDialogData((prev) => ({ ...prev, loading: true }));
                    try {
                        await axios.post(`${constants.API_URL}/instructor/admin/private_session`, {
                            member_id: showAppointmentDialogData.member_id,
                            service_id: showAppointmentDialogData.service._id,
                            time: selectedTime,
                            follow_up_accepted: isFollowup,
                        });
                        setShowAppointmentDialog(false);

                        privateSessions.refetch();
                        alert?.showAlert('המפגש נקבע בהצלחה');
                    } catch (error) {
                        alert?.showAlert(getLocalizedErrorMessage(error as AxiosError, 'he'), 'error');
                    } finally {
                        setShowAppointmentDialogData((prev) => ({ ...prev, loading: false }));
                    }
                }}
            />
            <div>
                <Card dir='rtl' style={{ marginTop: 20 }}>
                    <CardHeader title='מפגשים אחד על אחד' />
                    <PrivateSessionsTable
                        refetchFunction={privateSessions.refetch}
                        isPsychologist={profileData?.is_psychologist}
                        privateSessions={privateSessions.data}
                        loading={privateSessions.loading}
                        onPrivateSessionSetAppointment={(member_id: string, member_name: string, service: any) => {
                            setShowAppointmentDialogData({ name: member_name, service, member_id, loading: false });
                            setShowAppointmentDialog(true);
                        }}
                    />
                </Card>
                <Card dir='rtl' style={{ marginTop: 20 }}>
                    <CardHeader title='שירותים פרטניים' />
                    <PrivateServicesTable refetch={privateServices.refetch} privateServices={privateServices.data ?? []} />
                </Card>
            </div>
        </>
    );
};

export default OneToOneSessionsTab;
